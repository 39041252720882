<template>
  <main class="main">
    <section class="left">
      <div class="login_outer">
        <div class="login_inner">
          <div>
            <h1 class="text-2xl font-bold">註冊</h1>
            <!-- <h2>免費體驗30天！</h2> -->
          </div>

          <vs-alert v-if="form.errorMsg" color="danger" gradient>
            <template #title>發生錯誤，請稍候再試 😵</template>
            {{ form.errorMsg }}
          </vs-alert>

          <div>
            <ValidationObserver class="space-y-6" v-slot="{ invalid }">
              <ValidationProvider
                tag="div"
                class="relative"
                name="電子信箱"
                :rules="`${required ? 'required|email' : ''}`"
                v-slot="{ errors }"
              >
                <vs-input type="email" v-model="form.email" placeholder="電子信箱">
                  <template #icon>
                    <svg-icon icon-class="email" style="fill: black"></svg-icon>
                  </template>
                </vs-input>
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>

              <ValidationProvider
                tag="div"
                class="relative"
                name="密碼"
                :rules="`${required ? 'required|password' : ''}`"
                v-slot="{ errors }"
              >
                <vs-input
                  type="password"
                  v-model="form.password"
                  placeholder="密碼"
                  :visiblePassword="form.hasVisiblePassword"
                  @click-icon="form.hasVisiblePassword = !form.hasVisiblePassword"
                >
                  <template #icon>
                    <svg-icon v-if="form.password === ''" icon-class="password" style="fill: black"></svg-icon>
                    <svg-icon v-else-if="form.hasVisiblePassword" icon-class="eye_hide" style="fill: black"></svg-icon>
                    <svg-icon v-else icon-class="eye_view" style="fill: black"></svg-icon>
                  </template>
                </vs-input>
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>

              <ValidationProvider
                tag="div"
                class="relative"
                name="再次確認密碼"
                :rules="`${required ? 'required|confirmed:密碼' : ''}`"
                v-slot="{ errors }"
              >
                <vs-input
                  type="password"
                  v-model="form.confirmPassword"
                  placeholder="再次確認密碼"
                  :visiblePassword="form.hasVisiblePassword"
                  @click-icon="form.hasVisiblePassword = !form.hasVisiblePassword"
                >
                  <template #icon>
                    <svg-icon v-if="form.confirmPassword === ''" icon-class="password" style="fill: black"></svg-icon>
                    <svg-icon v-else-if="form.hasVisiblePassword" icon-class="eye_hide" style="fill: black"></svg-icon>
                    <svg-icon v-else icon-class="eye_view" style="fill: black"></svg-icon>
                  </template>
                </vs-input>
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>

              <ValidationProvider
                tag="div"
                class="relative"
                name="姓名"
                :rules="`${required ? 'required|max:20' : ''}`"
                v-slot="{ errors }"
              >
                <vs-input v-model="form.name" placeholder="姓名">
                  <template #icon>
                    <svg-icon icon-class="account" style="stroke: black" class="xs"></svg-icon>
                  </template>
                </vs-input>
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>

              <ValidationProvider
                tag="div"
                class="relative"
                name="手機號碼"
                :rules="`${required ? 'required|TW_phoneNum' : ''}`"
                v-slot="{ errors }"
              >
                <vs-input v-model="form.phoneNum" placeholder="手機號碼">
                  <template #icon>
                    <svg-icon icon-class="mobile" style="fill: black"></svg-icon>
                  </template>
                </vs-input>
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>

              <ValidationProvider tag="div" class="relative" name="商店名稱" :rules="`${required ? 'required' : ''}`" v-slot="{ errors }">
                <vs-input v-model="form.storeName" placeholder="商店名稱 (註冊後可隨時更改)">
                  <template #icon>
                    <svg-icon icon-class="store" style="fill: black"></svg-icon>
                  </template>
                </vs-input>
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>

              <ValidationProvider tag="div" class="relative" name="業務代碼" rules="max:20" v-slot="{ errors }">
                <vs-input v-model="form.sid" placeholder="業務代碼">
                  <template #icon>
                    <svg-icon icon-class="member" class="sm" style="fill: black"></svg-icon>
                  </template>
                </vs-input>
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>

              <ValidationProvider tag="div" class="relative" name="佈景主題"  v-slot="{ errors }">
                <vs-select  v-model="form.bhno" placeholder="佈景主題">
                  <vs-option
                    v-for="item in sceneryOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    {{ item.label }}
                  </vs-option>
                </vs-select>
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>

              <div class="text-sm flex items-start justify-between">
                <div class="space-x-5 text-gray-text_light">
                  <router-link :to="{ name: 'login' }">已是商家？ 登入</router-link>
                </div>
                <vs-button
                  :disabled="invalid"
                  class="font-medium"
                  ref="register"
                  size="xl"
                  style="border-radius: 12px"
                  color="success"
                  @click="register(invalid)"
                  >註冊</vs-button
                >
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </section>

    <section class="right"></section>
  </main>
</template>

<script>
export default {
  name: 'register',
  data() {
    return {
      issend: false, //是否傳送
      form: {
        name: '',
        sex: false,
        email: '',
        password: '',
        confirmPassword: '',
        phoneNum: '',
        storeName: '',
        sid: '',
        bhno: 96,
        errorMsg: '',
        hasVisiblePassword: false,
      },
      required: true,
      sceneryOptions: [
        {
          value: 96,
          label: '佈景主題 - Webline'
        }, {
          value: 97,
          label: '佈景主題 - Line'
        }
      ]
    }
  },
  computed: {
    _sexVal() {
      return this.form.sex ? 1 : 0
    },
    _userDevice() {
      let u = navigator.userAgent
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android終端
      let isiOS = /(iPhone|iPad|iPod|iOS)/.test(u)
      return isAndroid ? 1 : isiOS ? 2 : 0
    },
  },
  created() {
    this.form.sid = this.$route.query.sid
  },
  methods: {
    register(invalid) {
      if (invalid || this.issend) return
      this.issend = true

      const loading = this.$vs.loading({
        target: this.$refs.register,
        scale: '0.6',
        background: 'success',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/set/slogin/setRegister.php',
        headers: {
          account: this.form.email,
          pawd: this.form.password,
        },
        data: {
          lang: 0,
          sid: this.form.sid,
          uname: this.form.name,
          sname: this.form.storeName,
          tel: this.form.phoneNum,
          sex: this._sexVal,
          device: this._userDevice,
          bhno: this.form.bhno,
        },
      })
        .then((res) => {
          if (res.data.Success) {
            // 儲存token
            this.$store.commit('login/UPDATE_COOKIE', res.data.Data)

            // 取消驗證後清空輸入框，不然會跳錯誤訊息
            this.required = false
            // 清空輸入框
            this.$data.form = this.$options.data().form
            return res.data.Data.stoken
          } else {
            this.form.errorMsg = res.data.Message
          }
          this.issend = false
          loading.close()
        })
        .then((stoken) => {
          if (!stoken) return
          // 1.3.1 讀取網站參數
          this.$axios({
            url: 'front/overview/getWebParam.php',
            headers: {
              stoken,
            },
            kcval: true,
            data: {
              lang: 0,
            },
          }).then((res) => {
            if (res.data.Success) {
              // 儲存網站參數
              this.$store.commit('getWebData/UPDATE_COOKIE', res.data.Data)

              // 跳出提醒
              this.$myVS.openNoti('註冊成功', '將於 3 秒後跳轉至商家後台').then(() => {
                // 提醒關閉後跳轉
                this.$router.replace({
                  name: 'admin',
                })
              })
            }
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
a:hover {
  text-decoration: underline;
}

.main {
  @apply flex flex-col lg:flex-row min-h-screen text-gray-text_dark;
}

.left {
  @apply min-h-screen relative flex-shrink-0 flex-grow
  flex items-center justify-center;

  background: url('../assets/images/login/bg2.png') center/cover;

  @screen lg {
    min-width: 450px;
    background: white;
  }
}

.login_outer {
  @apply bg-white
    rounded-2xl lg:absolute 
    px-6 py-10 shadow-2xl
    flex items-center justify-center
    w-full h-auto m-3 sm:m-0
    lg:top-1/2 lg:-right-1/3 lg:transform lg:-translate-y-1/2;

  background: rgba(255, 255, 255, 1);
  // backdrop-filter: blur(5px);

  @screen sm {
    width: 400px;
    height: auto;
  }

  .login_inner {
    @apply w-full space-y-6;
  }
}

.right {
  @apply min-h-screen w-full lg:w-16/20 hidden lg:block;
  background: url('../assets/images/login/bg2.png') center/cover;
}

::v-deep .vs-input {
  border: none;
  background: rgba(var(--vs-gray-2), 1);
}

// ::v-deep .vs-select__input {
//   transform: translate(0) !important;
//   background: rgba(var(--vs-gray-2), 1) !important;

//   &:hover {
//     box-shadow: none;
//   }
// }

// ::v-deep .vs-select__input:hover ~ .vs-select__label {
//   margin-top: 0px;
// }

// ::v-deep .vs-select__input:hover ~ .vs-icon-arrow {
//   margin-top: -2px;
// }

// ::v-deep .vs-select__label {
//   @apply text-gray-text_light text-sm opacity-100;
// }

// ::v-deep .vs-select.activeOptions .vs-select__label {
//   margin-top: 0px;
// }
</style>
